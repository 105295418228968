import * as Yup from 'yup';

import { MAX_TOTAL_SIZE_BYTES } from './constants';

export const loginSchema = Yup.object().shape({
  email: Yup.string().email('input:requirements:email').required('input:requirements:required'),
  password: Yup.string().required('input:requirements:required'),
});

export const newPasswordRequestSchema = Yup.object().shape({
  email: Yup.string().email('input:requirements:email').required('input:requirements:required'),
});

export const accountWaitlistSchema = Yup.object().shape({
  email: Yup.string().email('input:requirements:email').required('input:requirements:required'),
});

export const accountNewPasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .min(8, 'form:error:password_requirements')
    .matches(/[A-Z]/, 'form:error:password_requirements')
    .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, 'form:error:password_requirements')
    .required('input:requirements:required'),
  confirm_new_password: Yup.string()
    .required('input:requirements:required')
    .oneOf([Yup.ref('new_password')], 'form:error:passwords_do_not_match'),
});

export const inviteFriendSchema = Yup.object().shape({
  email: Yup.string().email('input:requirements:email').required('input:requirements:required'),
});

export const updateAccountPasswordSchema = Yup.object().shape({
  password: Yup.string().required('input:requirements:required'),
  new_password: Yup.string()
    .min(8, 'form:error:password_requirements')
    .matches(/[A-Z]/, 'form:error:password_requirements')
    .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, 'form:error:password_requirements')
    .required('input:requirements:required'),
  confirm_new_password: Yup.string()
    .required('input:requirements:required')
    .oneOf([Yup.ref('new_password')], 'form:error:passwords_do_not_match'),
});

export const accountEmailSchema = Yup.object().shape({
  email: Yup.string().email('input:requirements:email').required('input:requirements:required'),
});

export const accountNewUserSchema = Yup.object().shape({
  name: Yup.string().required('input:requirements:name'),
  first_surname: Yup.string().required('input:requirements:first_surname'),
  second_surname: Yup.string().required('input:requirements:second_surname'),
  id_number: Yup.string()
    .matches(/^[0-9]{8}[a-zA-Z]$/, 'input:requirements:id_number')
    .required('input:requirements:id_number'),
  phone_number: Yup.string()
    .matches(/^[0-9]{9,13}$/, 'input:requirements:phone_number')
    .required('input:requirements:phone_number'),
  is_privacy_policy_checked: Yup.boolean().oneOf(
    [true],
    'input:requirements:is_privacy_policy_checked'
  ),
});

export const accountNewUserCompanySchema = accountNewUserSchema.shape({
  nif: Yup.string()
    .matches(/^([ABCDEFGHJNPQRSUVW]\d{7}[0-9A-J]|[KLMXYZ]\d{7}[A-Z]|\d{8}[a-zA-Z])$/, 'input:requirements:nif')
    .required('input:requirements:required'),
  corporate_name: Yup.string().required('input:requirements:required'),
  office_address: Yup.string().required('input:requirements:required'),
});

export const fileSchema = Yup.array()
  .of(
    Yup.mixed().test('fileType', 'input:requirements:only_image_files', (file) => {
      const acceptedFormats = [
        'application/pdf',
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/heic',
      ];

      return acceptedFormats.includes(file?.type);
    })
  )
  .test('required', 'input:requirements:file', (files) => {
    return files && files.length > 0;
  })
  .test('fileSize', 'input:requirements:file_size', (files) => {
    if (!files || files.length === 0) return true;
    const totalSize = files.reduce((sum, file) => sum + (file?.size || 0), 0);
    return totalSize <= MAX_TOTAL_SIZE_BYTES;
  });

export const accountAddressSchema = Yup.object().shape({
  address_street: Yup.string().required('input:requirements:required'),
  address_number: Yup.number().required('input:requirements:required'),
  address_stair: Yup.string(),
  address_floor_number: Yup.string().required('input:requirements:required'),
  address_door_number: Yup.string(),
  zip_code: Yup.string()
    .matches(/^[0-9]{5}$/, 'input:requirements:zip_code')
    .required('input:requirements:required'),
  city: Yup.string().required('input:requirements:required'),
  user_is_contract_holder: Yup.string().required('input:requirements:user_is_contract_holder'),
});

export const offboardContractSchema = Yup.object().shape({
  offboard: Yup.string()
    .required('input:requirements:required')
    .equals(['Baja'], 'input:requirements:offboard_contract'),
});

export const getInTouchSchema = Yup.object().shape({
  request: Yup.string().required('input:requirements:required'),
});

export const updateIBANSchema = Yup.object().shape({
  new_iban: Yup.string().required('input:requirements:required'),
  new_iban_confirm: Yup.string()
    .required('input:requirements:required')
    .oneOf([Yup.ref('new_iban')], 'input:requirements:new_iban'),
});

export const accountIBANSchema = Yup.object().shape({
  contracts: Yup.array().of(
    Yup.object().shape({
      iban: Yup.string().required('input:requirements:required'),
    })
  ),
});
