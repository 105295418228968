import styles from './LegalPolicies.module.css';

const CookiesPolicy = () => {
  return (
    <article className={styles.root}>
      <header>
        <h1 className={styles.title}>Política de cookies</h1>
      </header>
      <div className={styles.content}>
        <p>
          Muy lejos, más allá de las montañas de palabras, alejados de los países de las vocales y
          las consonantes, viven los textos simulados. Viven aislados en casas de letras, en la
          costa de la semántica, un gran océano de lenguas. Un riachuelo llamado Pons fluye por su
          pueblo y los abastece con las normas necesarias.
        </p>
        <p>
          Hablamos de un país paraisomático en el que a uno le caen pedazos de frases asadas en la
          boca. Ni siquiera los todopoderosos signos de puntuación dominan a los textos simulados;
          una vida, se puede decir, poco ortográfica. Pero un buen día, una pequeña línea de texto
          simulado, llamada Lorem Ipsum, decidió aventurarse y salir al vasto mundo de la gramática.
        </p>
        <h2>Términos y Condiciones Generales</h2>
        <p>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
          laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
          architecto beatae vitae dicta sunt explicabo.
        </p>
        <h3>1. Aceptación de los Términos</h3>
        <p>
          Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
          consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
        </p>
        <ul>
          <li>
            Uso responsable de la plataforma. At vero eos et accusamus et iusto odio dignissimos
            ducimus qui blanditiis praesentium voluptatum deleniti
          </li>
          <li>Cumplimiento de normativas vigentes</li>
          <li>Protección de datos personales</li>
        </ul>
        <h3>2. Política de Privacidad</h3>
        <p>
          At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
          voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati
          cupiditate non provident.
        </p>
        <h3>3. Responsabilidades del Usuario</h3>
        <ol>
          <li>Mantener la confidencialidad de las credenciales de acceso</li>
          <li>No compartir información sensible con terceros</li>
          <li>Reportar cualquier actividad sospechosa</li>
        </ol>
        <p>
          <strong>Nota importante:</strong> Itaque earum rerum hic tenetur a sapiente delectus, ut
          aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores
          repellat.
        </p>
      </div>
    </article>
  );
};

export default CookiesPolicy;
