import React, { useEffect, useCallback, useState } from 'react';
import HelloSign from 'hellosign-embedded';
import { useNavigate } from 'react-router-dom';

import useTranslations from '../../../i18n/useTranslations';
import { ROUTES } from '../../../routes';
import services from '../../../services';

import { Stepper } from '../Stepper';
import styles from './AccountSign.module.css';

const { onboardUser, generateDropboxSignUrl } = services;

const AccountSign = ({ embeddedSigningUrl }) => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const [signUrl, setSignUrl] = useState(null);

  const handleSignedAuth = useCallback(async () => {
    try {
      await onboardUser({});
      navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_SUCCESS}`);
    } catch (error) {
      navigate(ROUTES.ROOT);
    }
  }, [navigate]);

  useEffect(() => {
    const handleContracts = async () => {
      try {
        const { sign_url } = await generateDropboxSignUrl({});
        setSignUrl(sign_url);
      } catch (error) {
        navigate(ROUTES.ROOT);
      }
    };
  
    handleContracts();
  }, []);

  useEffect(() => {
    const embeddedSigningUrl = signUrl;

    if (embeddedSigningUrl) {
      const url = new URL(embeddedSigningUrl);
      const clientId = url.searchParams.get('client_id');

      if (clientId) {
        HelloSign.init(clientId);

        HelloSign.open({
          url: embeddedSigningUrl,
          container: document.getElementById('embedded-signing'),
          height: 700,
          width: 500,
          messageListener: function (eventData) {
            console.log(eventData);
            if (eventData.event === 'signature_request_signed') {
              handleSignedAuth();
            }
          },
        });
      } else {
        console.error('Client ID not found in the URL');
      }
    }
  }, [embeddedSigningUrl, handleSignedAuth, signUrl]);

  return (
    <Stepper currentStep={4}>
      <section className={styles.root}>
        <header className={styles.header}>
          <h1 className={styles.heading}>{t('account_sign:heading')}</h1>
          <p className={styles.subheading}>{t('account_sign:subheading')}</p>
        </header>
        <div className={styles.content}>
          <div id="embedded-signing" className={styles.embeddedSigningContainer}></div>
        </div>
      </section>
    </Stepper>
  );
};

export default AccountSign;
