import { useState } from 'react';

import useTranslations from '../../../../i18n/useTranslations';

import { Icon, Icons } from '../../../Icon';
import CardPromotedText from './CardPromotedText';
import CardDescription from './CardDescription';
import CardConfirm from './CardConfirm';
import styles from './Card.module.css';

const capitalizeText = (text) => {
  return text.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase());
};

const Card = ({ contract, onConfirm }) => {
  const { t, formatNumber } = useTranslations();
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(true);

  const handleClick = () => setConfirmIsOpen(true);
  const handleCancel = () => setConfirmIsOpen(false);

  const handleConfirm = () => {
    onConfirm(contract.product_type, contract.contract_id, isChecked);
    setConfirmIsOpen(false);
  };

  const handleCheckboxChange = () => {
    setIsChecked((prevChecked) => !prevChecked);
  };

  const isBestOffer =
    contract.ahorro_estimado_anual === null || contract.ahorro_estimado_anual === 0;

  return (
    <article className={styles.card} key={contract.contract_id}>
      <header className={styles.header}>
        <Icon size={48} name={contract.product_type} />
        <div className={styles.headerInfo}>
          <h2 className={styles.title} title={contract.address}>
            {contract.address}
          </h2>
          <p className={styles.subtitle} title={contract.supplier_name}>
            {isBestOffer
              ? t('general:wo_change_supplier')
              : t('general:change_supplier', { supplier: capitalizeText(contract.supplier_name) })}
          </p>
        </div>
        <button className={styles.close} onClick={handleClick}>
          <span className="visually-hidden">{t('action:remove_contract')}</span>
          <Icon size={20} name={Icons.Close} />
        </button>
      </header>
      <div className={styles.content}>
        <CardPromotedText
          isBestOffer={isBestOffer}
          title={contract?.highlighted_message?.head}
          text={contract?.highlighted_message?.subhead}
        />
        {contract?.additional_message ? (
          <CardDescription text={contract.additional_message} />
        ) : null}
      </div>
      {isBestOffer ? null : (
        <footer className={styles.footer}>
          <p className={styles.footerText}>
            <span>{t('general:with_zenvi')}</span>
            <strong>
              {t('general:price_monthly', { price: formatNumber(contract.cost_w_zenvi) })}
            </strong>
          </p>
          <p className={`${styles.footerText} ${styles.footerTextRight}`}>
            <span>{t('general:current_price')}</span>
            <strong>
              {t('general:price_monthly', { price: formatNumber(contract.cost_wo_zenvi) })}
            </strong>
          </p>
        </footer>
      )}
      <CardConfirm
        isOpen={confirmIsOpen}
        id={`${contract.product_type}-${contract.contract_id}`}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        isChecked={isChecked}
        onCheckboxChange={handleCheckboxChange}
      />
    </article>
  );
};

export default Card;
