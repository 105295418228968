import axios from 'axios';

const domain =
  process.env.REACT_APP_BACKEND_URL || 'https://backend-zenvi-5zv6ce4weq-no.a.run.app';
const subdomain = 'admin'

const getLateLightBills = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/light_contracts_without_recent_bill`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const getContractsToOnboard = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/light_customers_to_onboard`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const onboardNewContracts = async (body) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/onboard_contracts`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const getGeneralStatus = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/general_status`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const isUserAdmin = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/user_is_admin`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const EstimateNewNOnboardedBills = async (data) => {
  const formData = new FormData();
  data.forEach((file, index) => {
    if (file) {
      formData.append("files", file); 
    }
  });

  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/structure_new_onboarded_bills`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const GenerationInvitation = async (email) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/invite_new_user`, {email}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const getBillsToSend = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/get_bills_to_send`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const modifyAndSendBills = async (body) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/modify_and_send_bills`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const getNonProcessedOnboardingBills = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/get_non_processed_onboarding_bills`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const processNonProcessedOnboardingBills = async (body) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/process_non_processed_onboarding_bills`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const handleImpersonate = async (email) => {
  try {
      
      const token = localStorage.getItem('token');
      const response = await axios.post(`${domain}/${subdomain}/impersonate_user`, {email}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data
  } catch (err) {
      console.error(err);
      alert('Something went wrong.');
  }
};

const getOnboardingBillsToVerify = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/get_onboarding_estimated_bills_to_verify`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

const VerifyOnboardingBills = async (body) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${domain}/${subdomain}/verify_onboarding_estimated_bills`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting form data:', error);
    throw error;
  }
};

export default {
  getLateLightBills,
  isUserAdmin,
  EstimateNewNOnboardedBills,
  GenerationInvitation,
  getContractsToOnboard,
  onboardNewContracts,
  getGeneralStatus,
  getBillsToSend,
  modifyAndSendBills,
  getNonProcessedOnboardingBills,
  processNonProcessedOnboardingBills,
  handleImpersonate,
  getOnboardingBillsToVerify,
  VerifyOnboardingBills,
};
