import { useState } from 'react';
import { Formik, Form } from 'formik';

import useTranslations from '../../../../i18n/useTranslations';

import { UPDATE_IBAN } from '../../../../constants';
import { updateIBANSchema } from '../../../../validationSchemas';
import services from '../../../../services';
import { useContracts } from '../../../../context';
import { maskIBAN } from '../../../adapters';

import { Button } from '../../../Button';
import { FormikInput } from '../../../Input';
import { Feedback } from '../Feedback';
import stylesForm from '../../../Form/Form.module.css';
import styles from './UpdateIBAN.module.css';

const { updateIBAN } = services;

const UpdateIBAN = ({ contract }) => {
  const { t } = useTranslations();
  const { updateContract } = useContracts();
  const [feedback, setFeedback] = useState('');
  const [newIban, setNewIban] = useState('');

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const maskedIban = maskIBAN(values.new_iban);
      setNewIban(maskedIban);

      const response = await updateIBAN({
        contract_id: contract.contract_id.toString(),
        product_type: contract.product_type,
        new_iban: values.new_iban,
      });

      if (response.status === 200) {
        updateContract(contract.product_type, contract.contract_id, {
          iban: values.new_iban,
        });
        setFeedback('success');
        resetForm();
      } else {
        setFeedback('error');
      }
    } catch (error) {
      setFeedback('error');
    }
  };

  if (feedback === 'success') {
    return (
      <Feedback
        type="success"
        heading={t('contract:updateIBAN_contract:feedback:success')}
        subheading={t('contract:updateIBAN_contract:feedback:success_subheading', {
          iban: newIban,
        })}
      />
    );
  }

  if (feedback === 'error') {
    return (
      <Feedback
        type="error"
        heading={t('contract:updateIBAN_contract:feedback:error')}
        subheading={t('contract:updateIBAN_contract:feedback:error_subheading')}
      />
    );
  }

  return (
    <>
      <h2 className={styles.heading}>{t('contract:updateIBAN_contract:heading')}</h2>
      <p className={styles.subheading}>{t('contract:updateIBAN_contract:subheading')}</p>
      <div className={styles.action}>
        <Formik
          initialValues={UPDATE_IBAN}
          validationSchema={updateIBANSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, resetForm }) => (
            <Form className={stylesForm.form} noValidate>
              <div className={stylesForm.formGroup}>
                <div>
                  <FormikInput
                    label="input:new_iban"
                    name="new_iban"
                    error={errors['new_iban']}
                    touched={touched['new_iban']}
                    inputMode="text"
                    required
                  />
                </div>
                <div>
                  <FormikInput
                    label="input:new_iban_confirm"
                    name="new_iban_confirm"
                    error={errors['new_iban_confirm']}
                    touched={touched['new_iban_confirm']}
                    inputMode="text"
                    required
                  />
                </div>
              </div>
              <div className={stylesForm.formActions}>
                <Button isFullWidth type="submit">
                  {t('action:change_iban')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default UpdateIBAN;
