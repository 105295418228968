import { useState } from 'react';
import { Formik, Form } from 'formik';

import useTranslations from '../../../../i18n/useTranslations';
import { OFFBOARD_CONTRACT } from '../../../../constants';
import { offboardContractSchema } from '../../../../validationSchemas';
import services from '../../../../services';

import { Button } from '../../../Button';
import { FormikInput } from '../../../Input';
import { Feedback } from '../Feedback';
import stylesForm from '../../../Form/Form.module.css';
import styles from './OffboardContract.module.css';

const { offboardContract } = services;

const OffboardContract = ({ onClose, contract }) => {
  const { t } = useTranslations();
  const [feedback, setFeedback] = useState('');

  const handleCancel = async (resetForm) => {
    onClose();
    resetForm();
    setFeedback('');
  };

  const handleSubmit = async (values, { resetForm }) => {
    const response = await offboardContract({
      contract_id: contract.contract_id.toString(),
      product_type: contract.product_type,
    });
    if (response.status === 200) {
      setFeedback('success');
      resetForm();
    } else {
      setFeedback('error');
    }
  };

  if (feedback === 'success') {
    return (
      <Feedback
        type="success"
        heading={t('contract:offboard_contract:feedback:success')}
        subheading={t('contract:offboard_contract:feedback:success_subheading')}
      />
    );
  }

  if (feedback === 'error') {
    return (
      <Feedback
        type="error"
        heading={t('contract:offboard_contract:feedback:error')}
        subheading={t('contract:offboard_contract:feedback:error_subheading')}
      />
    );
  }

  return (
    <>
      <h2 className={styles.heading}>{t('contract:offboard_contract:heading')}</h2>
      <p className={styles.subheading}>{t('contract:offboard_contract:subheading')}</p>
      <div className={styles.action}>
        <Formik
          initialValues={OFFBOARD_CONTRACT}
          validationSchema={offboardContractSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, resetForm }) => (
            <Form className={stylesForm.form} noValidate>
              <div className={stylesForm.formGroup}>
                <div>
                  <FormikInput
                    label="input:offboard"
                    name="offboard"
                    error={errors['offboard']}
                    touched={touched['offboard']}
                    inputMode="text"
                    required
                  />
                </div>
              </div>
              <div className={stylesForm.formActions}>
                <Button isFullWidth type="button" isGosht onClick={() => handleCancel(resetForm)}>
                  {t('action:cancel')}
                </Button>
                <Button isFullWidth type="submit">
                  {t('action:offboard')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default OffboardContract;
