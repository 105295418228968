import PropTypes from 'prop-types';

import { Icon, Icons } from '../Icon';
import styles from './Collapsable.module.css';

const CollapsabePropTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const Collapsable = ({ title, children }) => {
  return (
    <details className={styles.root}>
      <summary className={styles.summary}>
        <h3 className={styles.title}>
          {title}
          <Icon size={20} name={Icons.ArrowDown} />
        </h3>
      </summary>
      <div className={styles.content}>{children}</div>
    </details>
  );
};

Collapsable.propTypes = CollapsabePropTypes;

export default Collapsable;
