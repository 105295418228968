import React, { useState, useEffect } from 'react';

import styles from './Input.module.css';

const Input = ({
  label,
  type = 'text',
  id,
  name,
  errorMessage = null,
  innerRef,
  disabled = false,
  isDark = false,
  autocomplete = 'off',
  ...rest
}) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const refProp = innerRef ? { ref: innerRef } : {};

  useEffect(() => {
    setIsInvalid(errorMessage ? true : false);
  }, [errorMessage]);

  return (
    <div
      className={`${styles.root} ${disabled ? styles.isDisabled : ''} ${
        isInvalid ? styles.hasError : ''
      } ${isFocused ? styles.isFocused : ''}`}
    >
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
      <input
        {...refProp}
        type={type}
        id={id}
        name={name}
        placeholder=" "
        className={`${styles.input} ${isDark ? styles.isDark : ''}`}
        disabled={disabled}
        autoComplete={autocomplete}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        {...rest}
      />
      {isInvalid && <span className={styles.errorMsg}>{errorMessage}</span>}
    </div>
  );
};

export default Input;
