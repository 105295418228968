import { Routes, Route } from 'react-router-dom';

import { ROUTES } from '../../routes';

import { AdminOperations } from './AdminOperations';
import { LateLightBills } from './LateLightBills';
import { UsersToOnboard } from './OnboardUsers';
import { AddNewBills } from './AddNewBills';
import { ImpersonateUser } from './ImpersonateUser';
import { GeneralStatus } from './GeneralStatus';
import { BillsToSend } from './BillsToSend';
import { NonProcessedBills } from './NonProcessedBills';
import { OnboardingBillsToVerify } from './OnboardingBillsToVerify';
import { AdminContextProvider } from '../../context';

const Admin = () => {
  return (
    <AdminContextProvider>
      <Routes>
        <Route path="/" element={<AdminOperations />} />
        <Route path={ROUTES.LATE_LIGHT_BILLS} element={<LateLightBills />} />
        <Route path={ROUTES.CONTRACTS_TO_ONBOARD} element={<UsersToOnboard />} />
        <Route path={ROUTES.ADD_NEW_BILLS} element={<AddNewBills />} />
        <Route path={ROUTES.IMPERSONATE_USER} element={<ImpersonateUser />} />
        <Route path={ROUTES.BILLS_TO_SEND} element={<BillsToSend />} />
        <Route path={ROUTES.NON_PROCESSED_BILLS} element={<NonProcessedBills />} />
        <Route path={ROUTES.ONBOARDING_BILLS_TO_VERIFY} element={<OnboardingBillsToVerify />} />
        <Route path={ROUTES.GENERAL_STATUS} element={<GeneralStatus />} />
      </Routes>
    </AdminContextProvider>
  );
};

export default Admin;
