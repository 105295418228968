import { useRef, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ROUTE_PARAMS, ROUTES } from '../../../../../routes';
import useTranslations from '../../../../../i18n/useTranslations';

import styles from './Chart.module.css';

const ChartBar = ({ bill, maxValue, index, activeItem, handleActive }) => {
  const { t } = useTranslations();
  const { type, id } = useParams();
  const isEmpty = bill.cost === 0;

  if (isEmpty) {
    return (
      <div key={bill.id} className={styles.chartBar}>
        <span className={styles.chartValue} style={{ '--graph-block-size': '100%' }} aria-hidden />
        <span className={styles.chartText}>{bill.issue_date_month}</span>
      </div>
    );
  }

  return (
    <div key={bill.id} className={styles.chartBar}>
      <Link
        to={ROUTES.BILL.replace(ROUTE_PARAMS.TYPE, type)
          .replace(ROUTE_PARAMS.CONTRACT_ID, id)
          .replace(ROUTE_PARAMS.BILL_ID, bill.id)}
        className={`${styles.chartValue} ${styles.withContent} ${
          activeItem === index ? styles.isActive : ''
        }`}
        style={{ '--graph-block-size': `${(bill.cost / maxValue) * 100}%` }}
        onFocus={() => handleActive(index)}
        onMouseEnter={() => handleActive(index)}
        aria-label={t('contract:bill_view:chart_bar_label', { month: bill.issue_date_month_long })}
      />
      <span className={styles.chartText}>{bill.issue_date_month}</span>
    </div>
  );
};

const Chart = ({ bills, maxValue, average, handleActive, activeItem }) => {
  const { t } = useTranslations();
  const panelRef = useRef(null);
  const [hasScroll, setHasScroll] = useState(false);

  useEffect(() => {
    if (panelRef.current) {
      panelRef.current.scrollLeft = panelRef.current.scrollWidth;

      setHasScroll(panelRef.current.scrollWidth > panelRef.current.clientWidth);
    }
  }, []);

  const handleScroll = () => {
    if (panelRef.current) {
      setHasScroll(panelRef.current.scrollLeft > 0);
    }
  };

  useEffect(() => {
    const panel = panelRef.current;
    if (panel) {
      panel.addEventListener('scroll', handleScroll);
      return () => panel.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <div
      ref={panelRef}
      data-has-scroll={hasScroll}
      className={`${styles.chart} ${average ? styles.hasValue : ''}`}
      style={
        average
          ? { '--graph-average-block-size': `calc(${(average / maxValue) * 100}% + 24px)` }
          : {}
      }
      role="group"
      aria-label={t('contract:bill_view:details')}
    >
      {bills.map((bill, index) => (
        <ChartBar
          key={bill.id}
          bill={bill}
          maxValue={maxValue}
          index={index}
          activeItem={activeItem}
          handleActive={handleActive}
        />
      ))}
    </div>
  );
};

export default Chart;
