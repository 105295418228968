import { Formik, Form } from 'formik';

import useTranslations from '../../../i18n/useTranslations';

import { EMAIL_FORM_INPUTS } from '../../../constants';
import { accountEmailSchema } from '../../../validationSchemas';

import { PublicLayout } from '../../../Layout';
// import { SocialLogin } from '../../SocialLogin';
import { FormikInput } from '../../Input';
import { Button } from '../../Button';
import stylesForm from '../../Form/Form.module.css';
import styles from './AccountEmail.module.css';

const AccountEmail = () => {
  const { t } = useTranslations();

  const handleSubmit = (values) => {
    console.log('AccountEmail values:', values);
  };

  return (
    <PublicLayout>
      <section className={styles.root}>
        <header className={styles.header}>
          <h1 className={styles.heading}>{t('account_email:heading')}</h1>
        </header>
        <div className={styles.content}>
          <Formik
            initialValues={EMAIL_FORM_INPUTS}
            validationSchema={accountEmailSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form className={stylesForm.form} noValidate>
                <div className={stylesForm.formGroup}>
                  <FormikInput
                    label="input:email"
                    name="email"
                    error={errors['email']}
                    touched={touched['email']}
                    required
                  />
                  {/* <SocialLogin /> */}
                </div>
                <div className={stylesForm.formActions}>
                  <Button isFullWidth type="submit">
                    {t('action:continue')}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </PublicLayout>
  );
};

export default AccountEmail;
