import useTranslations from '../../../i18n/useTranslations';

import { Icon, Icons } from '../../Icon';
import styles from './AddContract.module.css';

const AddContract = ({ isEdgeCase, onClick }) => {
  const { t } = useTranslations();
  const className = isEdgeCase ? styles.isEdgeCase : null;

  return (
    <button className={`${styles.root} ${className}`} onClick={onClick}>
      <div className={styles.icon}>
        <Icon size={24} name={Icons.Plus} />
      </div>
      <span>{t('action:add_contract')}</span>
    </button>
  );
};

export default AddContract;
