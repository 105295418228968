import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useContracts, useNotifications } from '../../context';
import useMatchMedia from '../useMatchMedia';
import { TABLET_QUERY, MOBILE_QUERY } from '../mediaqueries';
import { ROUTES } from '../../routes';

import { Loader } from '../Loader';
import { Card } from './Card';
import { AddContract } from './AddContract';
import { Detail } from './Detail';
import { List } from './List';
import { Alerts } from './Alerts';
import styles from './Dashboard.module.css';

const CONTRACTS_TO_SHOW_AS_LIST = 4;
const MAX_CONTRACTS_TO_SHOW_AS_CARDS = CONTRACTS_TO_SHOW_AS_LIST - 1;

const Dashboard = () => {
  const navigate = useNavigate();
  const isTablet = useMatchMedia(TABLET_QUERY);
  const isMobile = useMatchMedia(MOBILE_QUERY);
  const { contracts, automaticAdminActions, loading } = useContracts();
  const { notifications } = useNotifications();

  const showAsList = !isMobile && contracts.length >= CONTRACTS_TO_SHOW_AS_LIST;
  const isEdgeCase = contracts.length === MAX_CONTRACTS_TO_SHOW_AS_CARDS;

  const layoutClasses = useMemo(() => {
    return showAsList
      ? styles.listLayout
      : contracts.length === MAX_CONTRACTS_TO_SHOW_AS_CARDS
      ? styles.isEdgeCase
      : null;
  }, [contracts.length, showAsList]);

  const contractsList = useMemo(() => {
    if (showAsList) {
      return <List contracts={contracts} />;
    }
    return contracts.map((contract) => (
      <Card isEdgeCase={isEdgeCase} key={contract.contract_id} contract={contract} />
    ));
  }, [contracts, isEdgeCase, showAsList]);

  if (loading) {
    return <Loader />;
  }

  const handleAddContract = () => {
    navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_ADDRESS}`);
  };

  return (
    <div className={styles.root}>
      {notifications.length > 0 ? (
        <div className={styles.notificationsWrapper}>
          <Alerts notifications={notifications} />
        </div>
      ) : null}

      <div
        className={`${styles.inner} ${showAsList ? styles.hasList : styles.hasCards}`}
        {...{
          style: {
            ...(!showAsList ? { '--contract-row-columns': contracts.length } : {}),
          },
        }}
      >
        <div className={`${styles.row} ${styles.rowContracts} ${layoutClasses}`}>
          {contractsList}
          {isTablet && !isMobile ? (
            <AddContract
              isEdgeCase={contracts.length === MAX_CONTRACTS_TO_SHOW_AS_CARDS}
              onClick={handleAddContract}
            />
          ) : null}
        </div>

        {!isTablet || isMobile ? (
          <div className={styles.row}>
            <AddContract onClick={handleAddContract} />
          </div>
        ) : null}

        <div className={styles.row}>
          <Detail contracts={contracts} automaticAdminActions={automaticAdminActions} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
