import { useRef, useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import useTranslations from '../../../i18n/useTranslations';
import { maskIBAN } from '../../adapters';

import { Icons, Icon } from '../../Icon';
import { OffboardContract } from './OffboardContract';
import { GetInTouch } from './GetInTouch';
import { UpdateIBAN } from './UpdateIBAN';
import { ContractDialog } from './ContractDialog';
import styles from './ContractView.module.css';

const customIcon = new L.Icon({
  iconUrl:
    'data:image/svg+xml;base64,' +
    btoa(`
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.202 17.79C12.202 11.289 17.494 6 24 6c6.505 0 11.797 5.289 11.797 11.79 0 6.248-10.721 23.153-11.178 23.87a.735.735 0 0 1-1.24 0c-.456-.717-11.177-17.622-11.177-23.87m7.686-.772c0 2.183 1.845 3.959 4.111 3.959 2.268 0 4.112-1.776 4.112-3.96 0-2.183-1.845-3.959-4.111-3.959s-4.112 1.776-4.112 3.96" fill="#fff"/></svg>
  `),
  iconSize: [48, 48],
  iconAnchor: [24, 48],
  popupAnchor: [0, -48],
});

const ContractView = ({ contract }) => {
  const { t } = useTranslations();
  const offboardRef = useRef(null);
  const [action, setAction] = useState(null);
  const {
    contract_holder = '-',
    iban = '-',
    cups = '-',
    supplier: [companySupplier = '-'],
    latitude = 40.416775,
    longitude = -3.70379,
  } = contract;

  const handleOffboard = (event) => {
    event.stopPropagation();
    if (offboardRef.current && !offboardRef.current.open) {
      offboardRef.current.showModal();
      setAction('offboard');
    }
  };

  const handleGetInTouch = (event) => {
    event.stopPropagation();
    if (offboardRef.current && !offboardRef.current.open) {
      offboardRef.current.showModal();
      setAction('getInTouch');
    }
  };

  const handleUpdateIBAN = (event) => {
    event.stopPropagation();
    if (offboardRef.current && !offboardRef.current.open) {
      offboardRef.current.showModal();
      setAction('updateIBAN');
    }
  };

  const handleContractDialogClose = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (offboardRef.current) {
      offboardRef.current.classList.add(styles.isClosing);

      offboardRef.current.addEventListener(
        'animationend',
        () => {
          offboardRef.current.classList.remove(styles.isClosing);
          offboardRef.current.close();
          setAction(null);
        },
        { once: true }
      );
    }
  };

  useEffect(() => {
    const offboard = offboardRef.current;
    const handleClickOutside = (event) => {
      if (event.target === offboard) {
        handleContractDialogClose();
      }
    };

    offboard?.addEventListener('click', handleClickOutside);

    return () => {
      offboard?.removeEventListener('click', handleClickOutside);
    };
  }, [offboardRef]);

  const renderActionComponent = () => {
    switch (action) {
      case 'offboard':
        return <OffboardContract onClose={handleContractDialogClose} contract={contract} />;
      case 'getInTouch':
        return <GetInTouch contract={contract} />;
      case 'updateIBAN':
        return <UpdateIBAN contract={contract} />;
      default:
        return null;
    }
  };

  return (
    <section className={styles.root}>
      <div className={styles.detail}>
        <div className={styles.map}>
          <MapContainer
            key={`${latitude}-${longitude}`}
            center={[latitude, longitude]}
            zoom={18}
            style={{ height: '100%', width: '100%' }}
            scrollWheelZoom={false}
            zoomControl={false}
            attributionControl={false}
          >
            <TileLayer
              url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=ZENVI_API_KEY"
              attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>'
            />
            <Marker position={[latitude, longitude]} icon={customIcon} />
          </MapContainer>
        </div>
        <div className={styles.listWrapper}>
          <dl className={styles.list}>
            <div className={styles.item}>
              <dt className={styles.term}>{t('contract:contract_view:contract_holder')}</dt>
              <dd className={styles.definition}>{contract_holder}</dd>
            </div>
            <div className={styles.item}>
              <dt className={styles.term}>{t('contract:contract_view:iban')}</dt>
              <dd className={styles.definition}>{maskIBAN(iban)}</dd>
            </div>
            <div className={styles.item}>
              <dt className={styles.term}>{t('contract:contract_view:cups')}</dt>
              <dd className={styles.definition}>{cups}</dd>
            </div>
            <div className={styles.item}>
              <dt className={styles.term}>{t('contract:contract_view:supplier')}</dt>
              <dd className={styles.definition}>{companySupplier}</dd>
            </div>
          </dl>
        </div>
      </div>
      <ul className={styles.actions}>
        <li>
          <button className={styles.button} onClick={handleUpdateIBAN}>
            {t('action:change_iban')}
            <Icon size={20} name={Icons.ArrowRight} />
          </button>
        </li>
        <li>
          <button className={styles.button} onClick={handleOffboard}>
            {t('action:downgrade_contract')}
            <Icon size={20} name={Icons.ArrowRight} />
          </button>
        </li>
        <li>
          <button className={styles.button} onClick={handleGetInTouch}>
            {t('action:other_settings')}
            <Icon size={20} name={Icons.ArrowRight} />
          </button>
        </li>
      </ul>
      <ContractDialog ref={offboardRef} onClose={handleContractDialogClose}>
        {renderActionComponent()}
      </ContractDialog>
    </section>
  );
};

export default ContractView;
