import { useNavigate } from 'react-router-dom';

import useTranslations from '../../../i18n/useTranslations';
import { ROUTES } from '../../../routes';
import { useForm } from '../../../context';

import HomeIcon from '../../../assets/home.svg';
import BriefcaseIcon from '../../../assets/briefcase.svg';
import { PublicLayout } from '../../../Layout';
import styles from './AccountType.module.css';

const ACCOUNT_TYPES = {
  PARTICULAR: 'particular',
  COMPANY: 'company',
};

const AccountType = () => {
  const { updateFormData } = useForm();
  const navigate = useNavigate();
  const { t } = useTranslations();

  const handleNextStep = async (e) => {
    e.preventDefault();

    const isCompany = e.nativeEvent.submitter.value === ACCOUNT_TYPES.COMPANY;

    try {
      await updateFormData({ is_company: isCompany });
      navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_NEW_USER}`);
    } catch (error) {
      console.error(t('general:register:error'), error);
    }
  };

  return (
    <PublicLayout>
      <section className={styles.root}>
        <header className={styles.header}>
          <h1 className={styles.heading}>{t('account_type:heading')}</h1>
        </header>
        <div className={styles.content}>
          <form onSubmit={handleNextStep}>
            <div className={styles.controlButtons}>
              <button
                className={styles.controlButton}
                type="submit"
                value={ACCOUNT_TYPES.PARTICULAR}
              >
                <img width={112} height={112} src={HomeIcon} alt="" />
                <div className={styles.controlButtonText}>
                  <strong>{t('account_type:particular')}</strong>
                  <span>{t('account_type:particular_subheading')}</span>
                </div>
              </button>
              <button className={styles.controlButton} type="submit" value={ACCOUNT_TYPES.COMPANY}>
                <img width={112} height={112} src={BriefcaseIcon} alt="" />
                <div className={styles.controlButtonText}>
                  <strong>{t('account_type:company')}</strong>
                  <span>{t('account_type:company_subheading')}</span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </section>
    </PublicLayout>
  );
};

export default AccountType;
