import { Navigate } from 'react-router-dom';

import { useAuth, useContracts } from '../context';
import { ROUTES } from '../routes';
import { PublicLayout } from '../Layout';
import { Loader } from './Loader';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, loading: authLoading } = useAuth();
  const { loading: contractsLoading } = useContracts();

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.ROOT} replace />;
  }

  if (authLoading || contractsLoading) {
    return (
      <PublicLayout>
        <Loader />
      </PublicLayout>
    );
  }

  return children;
};

export default PrivateRoute;
