import useTranslations from '../../i18n/useTranslations';

import { Icon, Icons } from '../Icon';
import { Link } from '../Link';
import Collapsable from './Collapsable';
import styles from './FAQs.module.css';

// IDEA: Move this constants to a data file
const contact = [
  {
    label: 'hola@zenvi.com',
    href: 'mailto:hola@zenvi.com',
    icon: Icons.Email,
  },
  {
    label: '916 214 563',
    href: 'tel:+34916214563',
    icon: Icons.Phone,
  },
];

const FAQs = () => {
  const { t } = useTranslations();

  return (
    <article className={styles.root}>
      <aside>
        <ul className={styles.list}>
          {contact.map((item) => (
            <li key={item.label}>
              <Link className={styles.link} to={item.href}>
                <Icon size={48} name={item.icon} />
                <span>{item.label}</span>
              </Link>
            </li>
          ))}
        </ul>
      </aside>
      <section className={styles.content}>
        <div className={styles.section}>
          <h2 className={styles.title}>{t('faqs:term:1')}</h2>
          <div className={styles.sectionContainer}>
            <Collapsable title={t('faqs:term:1:1')}>
              <p>{t('faqs:term:1:1:content:0')}</p>
              <p>{t('faqs:term:1:1:content:1')}</p>
            </Collapsable>
            <Collapsable title={t('faqs:term:1:2')}>
              <p>{t('faqs:term:1:1:content:0')}</p>
              <p>{t('faqs:term:1:1:content:1')}</p>
            </Collapsable>
          </div>
        </div>
        <div className={styles.section}>
          <h2 className={styles.title}>{t('faqs:term:2')}</h2>
          <div className={styles.sectionContainer}>
            <Collapsable title={t('faqs:term:1:2')}>
              <p>{t('faqs:term:1:1:content:0')}</p>
              <p>{t('faqs:term:1:1:content:1')}</p>
            </Collapsable>
          </div>
        </div>
        <div className={styles.section}>
          <h2 className={styles.title}>{t('faqs:term:3')}</h2>
          <div className={styles.sectionContainer}>
            <Collapsable title={t('faqs:term:1:1')}>
              <p>{t('faqs:term:1:1:content:0')}</p>
              <p>{t('faqs:term:1:1:content:1')}</p>
            </Collapsable>
            <Collapsable title={t('faqs:term:1:2')}>
              <p>{t('faqs:term:1:1:content:0')}</p>
              <p>{t('faqs:term:1:1:content:1')}</p>
            </Collapsable>
          </div>
        </div>
      </section>
    </article>
  );
};

export default FAQs;
