import { createContext, useContext, useState, useEffect } from 'react';

import services from '../services';

const { verifyToken, logout: logoutService } = services;

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkToken = async () => {
    setLoading(true);
    try {
      const isValid = await verifyToken();
      setIsAuthenticated(isValid);
    } catch (error) {
      console.error('Error verifying token:', error);
    } finally {
      setLoading(false);
    }
    return Promise.resolve();
  };

  useEffect(() => {
    checkToken();

    const interval = setInterval(() => {
      console.log('Checking token periodically...');
      checkToken();
    }, 25 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const logout = async () => {
    try {
      await logoutService();
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      localStorage.removeItem('token');
      localStorage.clear();
      setIsAuthenticated(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        registrationComplete,
        setRegistrationComplete,
        loading,
        logout,
        checkToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
