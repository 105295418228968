import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useTranslations from '../../../i18n/useTranslations';
import { useForm } from '../../../context';
import { ROUTES } from '../../../routes';
import services from '../../../services/OnboardingServices';

import { Card } from './Card';
import { Button } from '../../Button';
import { Stepper } from '../Stepper';
import styles from './AccountEstimatedResults.module.css';

const { removeContracts } = services;

const AccountEstimatedResults = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { contractResult, setContractResult } = useForm();
  const [contractsToRemove, setContractsToRemove] = useState([]);

  const contractsNotOnboarded = (contractResult || []).filter(
    (contract) => !contract.iban_onboarded
  );

  const handleNextStep = async () => {
    try {
      await removeContracts(contractsToRemove);
      navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_IBAN}`);
    } catch (error) {
      console.error('Failed to navigate to next step:', error);
    }
  };

  const handleConfirm = (productType, contractId, isChecked) => {
    setContractsToRemove((prevContracts) => [
      ...prevContracts,
      { product_type: productType, contract_id: contractId, keep_in_loop: isChecked },
    ]);
    setContractResult((prevContracts) =>
      prevContracts.filter((contract) => contract.contract_id !== contractId)
    );
  };

  useEffect(() => {
    const handleContracts = async () => {
      if (contractResult && contractResult.length === 0) {
        try {
          await removeContracts(contractsToRemove);
          navigate(ROUTES.DASHBOARD);
        } catch (error) {
          console.error('Error removing contracts:', error);
        }
      }
    };

    handleContracts();
  }, [contractResult, contractsToRemove, navigate]);

  return (
    <Stepper currentStep={3}>
      <section className={styles.root}>
        <header className={styles.header}>
          <h1 className={styles.heading}>{t('account_estimated_results:heading')}</h1>
          <p className={styles.subheading}>{t('account_estimated_results:subheading')}</p>
        </header>
        <div className={styles.content}>
          <div className={styles.cardsContainer}>
            <div className={styles.cardsWrapper}>
              {contractsNotOnboarded &&
                contractsNotOnboarded.map((contract) => (
                  <Card
                    contract={contract}
                    key={`${contract.product_type}-${contract.contract_id}`}
                    onConfirm={handleConfirm}
                  />
                ))}
            </div>
          </div>
          <div className={styles.action}>
            <Button isFullWidth onClick={handleNextStep}>
              {t('action:continue')}
            </Button>
          </div>
        </div>
      </section>
    </Stepper>
  );
};

export default AccountEstimatedResults;
