import React, { useState } from "react";
import { useAdmin } from "../../../context/AdminContext";
import services from "../../../services";

const { VerifyOnboardingBills } = services;

const OnboardingBillsToVerify = () => {
  const { onboardingBillsToVerify } = useAdmin();
  const [selectedBills, setSelectedBills] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [editedData, setEditedData] = useState({});

  const allFields = onboardingBillsToVerify
    ? Array.from(new Set(onboardingBillsToVerify.flatMap(Object.keys)))
    : [];

  const nonEditableFields = ["bill_id", "contract_id", "email", "full_name"];

  const handleCheckboxChange = (customer) => {
    setSelectedBills((prevSelected) => {
      if (prevSelected.includes(customer)) {
        return prevSelected.filter((item) => item !== customer);
      } else {
        return [...prevSelected, customer];
      }
    });
  };

  const handleInputChange = (customerIndex, field, value) => {
    setEditedData((prevEditedData) => {
      const updatedData = { ...prevEditedData };
      if (!updatedData[customerIndex]) {
        updatedData[customerIndex] = {};
      }
      updatedData[customerIndex][field] = value;
      return updatedData;
    });
  };

  const handleSend = () => {
    const payload = selectedBills.map((customer) => {
      const customerIndex = onboardingBillsToVerify.findIndex(
        (bill) => bill.bill_id === customer.bill_id
      );
  
      const modifiedFields = editedData[customerIndex] || {};
      const is_modified = Object.keys(modifiedFields).length > 0;
  
      return {
        ...customer,
        ...modifiedFields,
        is_modified,
        cups: modifiedFields.cups !== undefined ? modifiedFields.cups : customer.cups,
        original_cups: customer.cups,
        product_type: modifiedFields.product_type !== undefined ? modifiedFields.product_type : customer.product_type,
        original_product_type: customer.product_type,
      };
    });
  
    setSuccessMessage("Contratos onboardeados.");
    VerifyOnboardingBills(payload);
  };

  if (!onboardingBillsToVerify || onboardingBillsToVerify.length === 0) {
    return <div>Everybody up to date</div>;
  }

  return (
    <div style={{ padding: "20px" }}>
      <h1 style={{ textAlign: "center", marginBottom: "20px" }}>Facturas a revisar y enviar</h1>
      <div style={{ overflowX: "auto" }}>
        <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "20px", tableLayout: "auto" }}>
          <thead>
            <tr style={{ backgroundColor: "#f4f4f4" }}>
              <th style={headerCellStyle}>Select</th>
              {allFields.map((field) => (
                <th key={field} style={headerCellStyle}>{field}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {onboardingBillsToVerify.map((customer, index) => (
              <tr key={index}>
                <td style={cellStyle}>
                  <input
                    type="checkbox"
                    checked={selectedBills.includes(customer)}
                    onChange={() => handleCheckboxChange(customer)}
                  />
                </td>
                {allFields.map((field) => (
                  <td key={field} style={cellStyle}>
                    {field === "bill_url" ? (
                      customer[field] ? (
                        customer[field] !== "" ? (
                          <a
                            href={customer[field]}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#007bff", textDecoration: "none" }}
                          >
                            bill
                          </a>
                        ) : (
                          "bill"
                        )
                      ) : (
                        "null"
                      )
                    ) : nonEditableFields.includes(field) ? (
                      customer[field] || ""
                    ) : (
                      <textarea
                        value={
                          editedData[index]?.[field] !== undefined
                            ? editedData[index][field]
                            : customer[field] === false
                            ? "false"
                            : customer[field] === 0 || customer[field] === 0.0 
                            ? "0.0"
                            : customer[field] || ""
                        }
                        onChange={(e) => handleInputChange(index, field, e.target.value)}
                        style={{ 
                          width: "100%", 
                          border: "none", 
                          outline: "none", 
                          resize: "none", 
                          overflowWrap: "break-word", 
                          whiteSpace: "normal", 
                          overflow: "hidden" 
                        }}
                        rows={Math.max(1, (customer[field]?.length || 0) / 20)}
                      />
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        onClick={handleSend}
        style={{
          padding: "10px 20px",
          backgroundColor: "#007bff",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          display: "block",
          margin: "0 auto",
        }}
      >
        Send
      </button>
      {successMessage && <p className="success">{successMessage}</p>}
    </div>
  );
};

const headerCellStyle = {
  padding: "10px",
  textAlign: "left",
  fontWeight: "bold",
  border: "1px solid #ddd",
  overflowWrap: "break-word",
};

const cellStyle = {
  padding: "10px",
  textAlign: "left",
  border: "1px solid #ddd",
  wordWrap: "break-word",
  whiteSpace: "normal",
  overflowWrap: "break-word",
};

export default OnboardingBillsToVerify;
