import { createContext, useContext, useState, useEffect } from 'react';

import services from '../services';
import { useAuth } from './AuthContext';
import { useForm } from './FormContext';

const { getNotifications, getComputedBillEstimates } = services;

const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { setSignUrl, setContractResult } = useForm();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const notificationTypeCheck = (list, searchString) => {
    if (!Array.isArray(list)) {
      console.error("First argument must be an array");
      return false;
    }
  
    return list.some((obj) => obj.message === searchString);
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      if (isAuthenticated) {
        try {
          setLoading(true);
          setError(null);
          const notificationsData = await getNotifications();
          const isAuthAlert = notificationTypeCheck(notificationsData, "Firme la autorización para que podamos proceder con los cambios")
          const notificationsWithIds = notificationsData.map((notification) => ({
            ...notification,
            id: crypto.randomUUID(),
          }));
          setNotifications(notificationsWithIds);
          const isIBANAlert = notificationTypeCheck(notificationsData, "Añada IBAN a sus contratos")
          if (isIBANAlert){
            const billEstimates = await getComputedBillEstimates();
            setContractResult(billEstimates.data);
          }
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchNotifications();
  }, [isAuthenticated]);

  const removeNotification = (id) => {
    setNotifications(notifications.filter((notification) => notification.id !== id));
  };

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        loading,
        error,
        removeNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error('useNotifications debe usarse dentro de NotificationsProvider');
  }
  return context;
};
