import useTranslations from '../../../i18n/useTranslations';
import { ROUTES, ROUTE_PARAMS } from '../../../routes';

import { Icon, Icons } from '../../Icon';
import { Link } from '../../Link';
import styles from './List.module.css';

const getLastInvoiceCost = (cost) => {
  return cost.toReversed().find((value) => value > 0);
};

const List = ({ contracts }) => {
  const { t, formatNumber } = useTranslations();

  const listHeader = (
    <li className={`${styles.item} ${styles.itemHeader}`}>
      <div className={styles.address}>
        <span>{t('dashboard:list:address')}</span>
      </div>
      <div className={styles.supplier}>
        <span>{t('dashboard:list:supplier')}</span>
      </div>
      <div className={styles.lastInvoice}>
        <span>{t('dashboard:list:last_invoice')}</span>
      </div>
      <div className={styles.action}>
        <span aria-hidden>&nbsp;</span>
      </div>
    </li>
  );

  return (
    <section className={styles.root}>
      <h3 className={styles.title}>{t('general:contracts')}</h3>
      <ul className={styles.list}>
        {listHeader}
        {contracts.map((contract) => (
          <ItemList key={contract.contract_id} contract={contract} formatter={formatNumber} />
        ))}
      </ul>
    </section>
  );
};

const ItemList = ({ contract, formatter }) => {
  const {
    product_type,
    address,
    contract_id,
    cost,
    supplier: [companySupplier = '-'],
  } = contract;

  const lastInvoice = getLastInvoiceCost(cost);

  return (
    <li className={styles.item}>
      <div className={styles.address}>
        <Icon size={32} name={product_type} />
        <span>{address}</span>
      </div>
      <div className={styles.supplier}>
        <span title={companySupplier}>{companySupplier}</span>
      </div>
      <div className={styles.lastInvoice}>
        <span>{lastInvoice ? formatter(lastInvoice) : '-'}</span>
      </div>
      <div className={styles.action}>
        <Link
          to={
            ROUTES.CONTRACT.replace(ROUTE_PARAMS.TYPE, product_type).replace(
              ROUTE_PARAMS.CONTRACT_ID,
              contract_id
            ) + ROUTE_PARAMS.BILL_VIEW
          }
        >
          <Icon size={20} name={Icons.ArrowRight} />
        </Link>
      </div>
    </li>
  );
};

export default List;
