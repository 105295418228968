import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form, useFormikContext } from 'formik';

import useTranslations from '../../../i18n/useTranslations';
import { ROUTES } from '../../../routes';
import { useAuth, useForm } from '../../../context';
import services from '../../../services';
import { PASSWORD_FORM_INPUTS } from '../../../constants';
import { accountNewPasswordSchema } from '../../../validationSchemas';

import { PublicLayout } from '../../../Layout';
import { FormikInput } from '../../Input';
import { Button } from '../../Button';
import { Icons, Icon } from '../../Icon';
import stylesForm from '../../Form/Form.module.css';
import styles from './AccountPassword.module.css';

const { createNewPassword } = services;

const PasswordRequirements = ({ passwordValidations }) => {
  const { t } = useTranslations();

  return (
    <ul className={styles.requirements}>
      <li className={passwordValidations.minLength ? styles.isValid : ''}>
        {t('input:confirm_password:requirements:min_length')}
      </li>
      <li className={passwordValidations.uppercase ? styles.isValid : ''}>
        {t('input:confirm_password:requirements:uppercase')}
      </li>
      <li className={passwordValidations.specialChar ? styles.isValid : ''}>
        {t('input:confirm_password:requirements:special_char')}
      </li>
    </ul>
  );
};

const AccountPassword = () => {
  const { refreshContext } = useForm();
  const { checkToken } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslations();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token') || localStorage.getItem('token');
  const [passwordVisibility, setPasswordVisibility] = useState({
    new_password: false,
    confirm_new_password: false,
  });

  const [passwordValidations, setPasswordValidations] = useState({
    minLength: false,
    uppercase: false,
    specialChar: false,
  });

  const handleNextStep = async (values) => {
    try {
      const res = await createNewPassword({
        token: token,
        new_password: values.new_password,
      });
      localStorage.setItem('token', res.access_token);
      await checkToken();
      const { userIsAdmin, userDetailsRegistered, anyEstimatedBill } = await refreshContext();

      if (userIsAdmin) {
        navigate(ROUTES.ADMIN);
        return;
      }

      if (!userDetailsRegistered) {
        navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_NEW_TYPE}`);
        return;
      }

      if (!anyEstimatedBill) {
        navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_ADDRESS}`);
        return;
      }
      navigate(ROUTES.DASHBOARD);
    } catch (error) {
      console.error(t('general:register:error'), error);
    }
  };

  const FormikPasswordValidator = () => {
    const { values } = useFormikContext();

    React.useEffect(() => {
      const password = values.new_password || '';
      const newValidations = {
        minLength: password.length >= 8,
        uppercase: /[A-Z]/.test(password),
        specialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password),
      };

      if (JSON.stringify(newValidations) !== JSON.stringify(passwordValidations)) {
        setPasswordValidations(newValidations);
      }
    }, [values.new_password]);

    return null;
  };

  const togglePasswordVisibility = (fieldName) => {
    setPasswordVisibility((prev) => ({
      ...prev,
      [fieldName]: !prev[fieldName],
    }));
  };

  return (
    <PublicLayout>
      <section className={styles.root}>
        <header className={styles.header}>
          <h1 className={styles.heading}>{t('account_new_password:heading')}</h1>
        </header>
        <div className={styles.content}>
          <Formik
            initialValues={PASSWORD_FORM_INPUTS}
            validationSchema={accountNewPasswordSchema}
            onSubmit={handleNextStep}
          >
            {({ errors, touched }) => (
              <>
                <FormikPasswordValidator />
                <Form className={stylesForm.form} noValidate>
                  <div className={stylesForm.formGroup}>
                    <div className={stylesForm.passwordContainer}>
                      <FormikInput
                        type={passwordVisibility.new_password ? 'text' : 'password'}
                        label="input:new_password"
                        name="new_password"
                        error={errors['new_password']}
                        touched={touched['new_password']}
                        inputMode="text"
                        required
                      />
                      <button
                        type="button"
                        onClick={() => togglePasswordVisibility('new_password')}
                        className={stylesForm.togglePassword}
                      >
                        <Icon
                          size={20}
                          name={passwordVisibility.new_password ? Icons.EyeSlash : Icons.Eye}
                        />
                        <span className="visually-hidden">
                          {passwordVisibility.new_password
                            ? t('action:hide_password')
                            : t('action:show_password')}
                        </span>
                      </button>
                    </div>
                    <div className={stylesForm.passwordContainer}>
                      <FormikInput
                        type={passwordVisibility.confirm_new_password ? 'text' : 'password'}
                        label="input:confirm_password"
                        name="confirm_new_password"
                        error={errors['confirm_new_password']}
                        touched={touched['confirm_new_password']}
                        inputMode="text"
                        required
                      />
                      <button
                        type="button"
                        onClick={() => togglePasswordVisibility('confirm_new_password')}
                        className={stylesForm.togglePassword}
                      >
                        <Icon
                          size={20}
                          name={
                            passwordVisibility.confirm_new_password ? Icons.EyeSlash : Icons.Eye
                          }
                        />
                        <span className="visually-hidden">
                          {passwordVisibility.confirm_new_password
                            ? t('action:hide_password')
                            : t('action:show_password')}
                        </span>
                      </button>
                    </div>
                    <PasswordRequirements passwordValidations={passwordValidations} />
                  </div>
                  <div className={stylesForm.formActions}>
                    <Button isFullWidth type="submit">
                      {t('action:continue')}
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </section>
    </PublicLayout>
  );
};

export default AccountPassword;
