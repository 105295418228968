import { forwardRef, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import useTranslations from '../../../../i18n/useTranslations';
import { useAuth } from '../../../../context';
import { ROUTES } from '../../../../routes';

import { Button, Icon, Icons } from '../../../../components';
import { Confirm } from './Confirm';
import { UpdatePassword } from './UpdatePassword';
import { InviteFriend } from './InviteFriend';
import styles from './Dialog.module.css';

const Dialog = forwardRef(({ onClose, user }, ref) => {
  const { t } = useTranslations();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const updatePasswordRef = useRef(null);
  const inviteFriendRef = useRef(null);
  const confirmRef = useRef(null);

  const handleLogoutClick = () => {
    confirmRef.current?.showModal();
  };

  const handleConfirm = () => {
    logout();
    navigate(ROUTES.ROOT);
    onClose();
  };

  const handleConfirmClose = () => {
    confirmRef.current?.close();
  };

  const handleInviteFriendClick = () => {
    inviteFriendRef.current?.showModal();
  };

  const handleInviteFriendClose = () => {
    inviteFriendRef.current?.close();
  };

  const handleUpdatePasswordClick = () => {
    updatePasswordRef.current?.showModal();
  };

  const handleUpdatePasswordClose = () => {
    updatePasswordRef.current?.close();
  };

  const handleClickOutside = (event) => {
    if (event.target === ref.current) {
      onClose();
    }
  };

  return (
    <>
      <dialog
        ref={ref}
        className={styles.root}
        onKeyDown={(event) => event.key === 'Escape' && onClose(event)}
        onClick={handleClickOutside}
      >
        <div className={styles.wrapper}>
          <header className={styles.header}>
            <button className={styles.close} type="button" onClick={onClose}>
              <span className="visually-hidden">{t('action:close')}</span>
              <Icon size={32} name={Icons.Close} />
            </button>
          </header>
          <div className={styles.content}>
            <h2 className={styles.heading}>{user?.full_name}</h2>
            <p className={styles.subheading}>{user?.id_number}</p>
          </div>
          <footer className={styles.footer}>
            <div className={styles.actions}>
              <Button isSecondary onClick={handleInviteFriendClick}>
                {t('action:invite_friend')} <Icon size={20} name={Icons.ArrowRight} />
              </Button>
              <Button isSecondary onClick={handleUpdatePasswordClick}>
                {t('action:change_password')} <Icon size={20} name={Icons.ArrowRight} />
              </Button>
              <Button isSecondary onClick={handleLogoutClick}>
                {t('action:logout')} <Icon size={20} name={Icons.Logout} />
              </Button>
            </div>
          </footer>
        </div>
      </dialog>
      <Confirm ref={confirmRef} onConfirm={handleConfirm} onClose={handleConfirmClose} />
      <UpdatePassword ref={updatePasswordRef} onClose={handleUpdatePasswordClose} />
      <InviteFriend ref={inviteFriendRef} onClose={handleInviteFriendClose} />
    </>
  );
});

export default Dialog;
