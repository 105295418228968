import { Navigate } from 'react-router-dom';

import { ROUTES } from '../routes';
import { useAuth, useForm, useAppReady } from '../context';
import { PublicLayout } from '../Layout';
import { Loader } from './Loader';

const PublicRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { appReady } = useAppReady();
  const { userDetailsRegistered, userIsAdmin, anyEstimatedBill, isTempPassword } = useForm();

  if (!appReady) {
    return (
      <PublicLayout>
        <Loader />
      </PublicLayout>
    );
  }

  if (!isAuthenticated) {
    return children;
  }

  if (userIsAdmin) {
    return <Navigate to={ROUTES.ADMIN} replace />;
  }

  if (isTempPassword) {
    return <Navigate to={`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_PASSWORD}`} replace />;
  }

  if (!userDetailsRegistered) {
    return <Navigate to={`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_NEW_TYPE}`} replace />;
  }

  if (!anyEstimatedBill) {
    return <Navigate to={`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_ADDRESS}`} replace />;
  }

  return <Navigate to={ROUTES.DASHBOARD} replace />;
};

export default PublicRoute;
