import React, { createContext, useContext, useState } from 'react';

const AdminContext = createContext();

export const AdminContextProvider = ({ children }) => {
  
  const [lightCustomersData, setLightCustomersData] = useState(null);
  const [lightContractsToOnboard, setLightContractsToOnboard] = useState(null);
  const [billsToSend, setBillsToSend] = useState(null);
  const [onboardingBillsToVerify, setOnboardingBillsToVerify] = useState(null);
  const [nonProcessedOnboardingBills, setNonProcessedOnboardingBills] = useState(null);
  const [generalStatus, setGeneralStatus] = useState(null);

  return (
    <AdminContext.Provider
      value={{
        lightCustomersData,
        setLightCustomersData,
        lightContractsToOnboard,
        setLightContractsToOnboard,
        generalStatus,
        setGeneralStatus,
        billsToSend,
        setBillsToSend,
        nonProcessedOnboardingBills,
        setNonProcessedOnboardingBills,
        onboardingBillsToVerify,
        setOnboardingBillsToVerify,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => useContext(AdminContext); 