import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from '../../../routes';

import services from "../../../services";

const {
  handleImpersonate,
} = services;

const ImpersonateUser = () => {
  const [email_to_invite, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSend = async () => {
    try {
      const response = await handleImpersonate(email_to_invite)
      localStorage.setItem('token', response.access_token);
      alert('Switched to customer view');
      window.location.href = `${window.location.origin}${ROUTES.DASHBOARD}?redirect=true`;
    } catch (error) {
      console.error("Error impersonating user:", error);
    }
  };

  return (
    <div>
      <h1>Introduzca email de usuario a impersonar</h1>
      <div>
        <input
          type="email"
          value={email_to_invite}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email de usuario"
          style={{ padding: "8px", marginRight: "10px", width: "300px" }}
        />
        <button onClick={handleSend} style={{ padding: "8px 16px" }}>
          Send
        </button>
      </div>
    </div>
  );
};

export default ImpersonateUser;
