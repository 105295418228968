import { useEffect, forwardRef, useState } from 'react';

import useTranslations from '../../../../i18n/useTranslations';

import { Button } from '../../../Button';
import { Checkbox } from '../../../Input';
import styles from './confirm.module.css';

const Confirm = forwardRef(({ id, onCancel, onConfirm }, ref) => {
  const { t } = useTranslations();
  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    if (ref) {
      ref.current = {
        showModal: () => ref.current?.showModal(),
        close: () => ref.current?.close(),
      };
    }
  }, [ref]);

  return (
    <dialog ref={ref} className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.confirm}>
            <h3 className={styles.title}>{t('account_estimated_results:card:confirm:heading')}</h3>
            <Checkbox
              id={id}
              isOpen={true}
              isChecked={isChecked}
              onCheckboxChange={handleCheckboxChange}
            />
            <div className={styles.actions}>
              <Button isFullWidth isGosht onClick={onCancel}>
                {t('general:no')}
              </Button>
              <Button isFullWidth isNegative onClick={() => onConfirm(isChecked)}>
                {t('general:yes')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </dialog>
  );
});

export default Confirm;
