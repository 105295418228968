import { Link } from 'react-router-dom';

import { useContracts } from '../../../context';
import { ROUTES } from '../../../routes';

import { Logo } from '../../Logo';
// import { NotificationsButton } from './NotificatonsButton';
import { DropdownButton } from './DropdownButton';
import styles from './Header.module.css';

const DashboardHeader = () => {
  const { user } = useContracts();
  return (
    <header className={styles.root}>
      <div className={styles.inner}>
        {/* <NotificationsButton /> */}
        <div aria-hidden="true" />
        <Link className={styles.logo} to={ROUTES.DASHBOARD}>
          <Logo />
        </Link>
        <DropdownButton user={user} />
      </div>
    </header>
  );
};

export default DashboardHeader;
