import PropTypes from 'prop-types';

import { Icon, Icons } from '../Icon';
import styles from './EmptyState.module.css';

const EmptyStatePropTypes = {
  message: PropTypes.string.isRequired,
};

const EmptyState = ({ message }) => (
  <div className={`${styles.root} ${styles.isAbsolute}`}>
    <Icon size={40} name={Icons.Graph} />
    <p>{message}</p>
  </div>
);

EmptyState.propTypes = EmptyStatePropTypes;

export default EmptyState;
