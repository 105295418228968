import { Detail } from './Detail';
import { List } from './List';
import styles from './BillView.module.css';

const BillView = ({ contractWithBills }) => (
  <section className={styles.root}>
    <Detail bills={contractWithBills.bills} />
    <List contractWithBills={contractWithBills} />
  </section>
);

export default BillView;
