import { useNavigate, Link } from 'react-router-dom';

import useTranslations from '../../i18n/useTranslations';
import { ROUTES } from '../../routes';

import { Logo } from '../Logo';
import { Icon, Icons } from '../../components';
import styles from './DefaultLayout.module.css';

const DefaultLayout = ({ titleKey, withLogo = false, children }) => {
  const { t } = useTranslations();
  const navigate = useNavigate();

  return (
    <main className={styles.root}>
      <header className={styles.header}>
        <div className={styles.inner}>
          <button className={styles.backButton} onClick={() => navigate(-1)}>
            <Icon size={40} name={Icons.ChevronLeft} />
          </button>
          <span className={styles.title}>{t(titleKey)}</span>
          {withLogo ? (
            <Link className={styles.logo} to={ROUTES.DASHBOARD}>
              <Logo />
            </Link>
          ) : null}
        </div>
      </header>
      <section className={styles.content}>
        <div className={styles.wrapper}>{children}</div>
      </section>
    </main>
  );
};

export default DefaultLayout;
