import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, FieldArray } from 'formik';

import useTranslations from '../../../i18n/useTranslations';
import { ROUTES } from '../../../routes';
import { useForm } from '../../../context';
import services from '../../../services';
import { ACCOUNT_IBAN_FORM_INPUTS } from '../../../constants';
import { accountIBANSchema } from '../../../validationSchemas';

import stylesForm from '../../Form/Form.module.css';
import { Icon, Icons } from '../../Icon';
import { FormikInput } from '../../Input';
import { Button } from '../../Button';
import { Stepper } from '../Stepper';
import { Dialog } from './Dialog';
import { Confirm } from './Confirm';
import styles from './AccountIBAN.module.css';

const { registerUserWithIBAN, isUserOnboarded, removeContracts } = services;

const AccountIBAN = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { contractResult, setContractResult } = useForm();
  const dialogRef = useRef(null);
  const [contractsToRemove, setContractsToRemove] = useState([]);
  const [contractToRemoveIndex, setContractToRemoveIndex] = useState(null);

  const confirmRefs = useRef({});

  const contractsNotOnboarded = (contractResult || []).filter(
    (contract) => !contract.iban_onboarded
  );
  const masterIndex = 0;

  useEffect(() => {
    const handleContractResult = async () => {
      if (
        contractResult === null ||
        contractResult?.every((contract) => contract.iban_onboarded === true)
      ) {
        try {
          const { success } = await isUserOnboarded(contractResult);
          if (!success) {
            navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_AUTH_SIGN}`);
          } else {
            navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_SUCCESS}`);
          }
        } catch (error) {
          console.error('Error processing contract result:', error);
          navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_SUCCESS}`);
        }
      }
    };

    handleContractResult();
  }, [contractResult, navigate]);

  const handleSubmit = async (values) => {
    const contractResultWithIBAN = contractsNotOnboarded
      .filter((_, index) => values.contracts[index]?.iban)
      .map((contract, index) => ({
        contract_id: contract.contract_id,
        product_type: contract.product_type,
        iban: values.contracts[index].iban,
      }));

    try {
      if (contractsToRemove.length > 0) {
        await removeContracts(contractsToRemove);
      }

      await registerUserWithIBAN(contractResultWithIBAN);
      const { success } = await isUserOnboarded(contractResultWithIBAN);

      if (!success) {
        navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_AUTH_SIGN}`);
      } else {
        navigate(`${ROUTES.ACCOUNT_ROOT}${ROUTES.ACCOUNT_SUCCESS}`);
      }
    } catch (err) {
      console.error(t('form:error:onboarding:iban'), err);
    }
  };

  const handleDialog = () => dialogRef.current.showModal();
  const handleCloseDialog = () => dialogRef.current.close();
  const handleCancel = () => {
    if (contractToRemoveIndex !== null) {
      confirmRefs.current[contractToRemoveIndex]?.close();
      setContractToRemoveIndex(null);
    }
  };

  const handleClick = (index) => {
    setContractToRemoveIndex(index);
    confirmRefs.current[index]?.showModal();
  };

  return (
    <Stepper currentStep={3}>
      <section className={styles.root}>
        <header className={styles.header}>
          <h1 className={styles.heading}>{t('account_iban:heading')}</h1>
          <p className={styles.subheading}>{t('account_iban:subheading')}</p>
        </header>
        <div className={styles.content}>
          <Formik
            initialValues={ACCOUNT_IBAN_FORM_INPUTS(contractsNotOnboarded)}
            validationSchema={accountIBANSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form className={styles.form} noValidate>
                <fieldset className={styles.fieldset}>
                  <FieldArray name="contracts">
                    {({ remove }) => (
                      <div className={styles.boxWrapper}>
                        {values.contracts.map((contract, index) => (
                          <div key={contract.contract_id} className={styles.box}>
                            <Confirm
                              ref={(el) => (confirmRefs.current[index] = el)}
                              id={`${contract.product_type}-${contract.contract_id}`}
                              onCancel={handleCancel}
                              onConfirm={(keepInLoop) => {
                                if (contractToRemoveIndex !== null) {
                                  const contractToRemove =
                                    contractsNotOnboarded[contractToRemoveIndex];
                                  const currentConfirm = confirmRefs.current[contractToRemoveIndex];

                                  setContractsToRemove((prev) => [
                                    ...prev,
                                    {
                                      product_type: contractToRemove.product_type,
                                      contract_id: contractToRemove.contract_id,
                                      keep_in_loop: keepInLoop,
                                    },
                                  ]);

                                  remove(contractToRemoveIndex);
                                  setContractResult((prev) => {
                                    const newFormData = [...prev];
                                    newFormData.splice(contractToRemoveIndex, 1);
                                    return newFormData;
                                  });
                                  currentConfirm?.close();
                                  setContractToRemoveIndex(null);
                                }
                              }}
                            />
                            <div className={styles.boxHeader}>
                              <Icon size={48} name={contract.product_type} />
                              <h2 className={styles.boxHeading}>{contract.address}</h2>
                              <button
                                type="button"
                                className={styles.boxHeaderButton}
                                onClick={() => handleClick(index)}
                              >
                                <span className="visually-hidden">{t('action:remove')}</span>
                                <Icon size={32} name={Icons.Close} />
                              </button>
                            </div>
                            <div className={stylesForm.formGroup}>
                              <FormikInput
                                label="input:iban"
                                name={`contracts.${index}.iban`}
                                error={errors.contracts?.[index]?.iban}
                                touched={touched.contracts?.[index]?.iban}
                                required
                                isDark
                                value={contract.iban || ''}
                                onChange={(e) => {
                                  const value = e.target.value || '';
                                  setFieldValue(`contracts.${index}.iban`, value);

                                  if (index === masterIndex) {
                                    values.contracts.forEach((_, i) => {
                                      if (i !== index) {
                                        setFieldValue(`contracts.${i}.iban`, value);
                                      }
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                  <button type="button" className={styles.button} onClick={handleDialog}>
                    {t('account_iban:dialog:button')}
                  </button>
                </fieldset>
                <Button isFullWidth type="submit">
                  {t('action:continue')}
                </Button>
              </Form>
            )}
          </Formik>
          <Dialog ref={dialogRef} onClose={handleCloseDialog} />
        </div>
      </section>
    </Stepper>
  );
};

export default AccountIBAN;
