import { Link } from '../../../components';
import data from './data';
import styles from './Footer.module.css';

const DashboardFooter = () => {
  const { menu } = data;

  return (
    <footer className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.menu}>
          {menu.map((item) => (
            <Link to={item.href} key={item.label}>
              {item.label}
            </Link>
          ))}
        </div>
        {/* <div className={styles.contact}>
          {contact.map((item) => (
            <Link to={item.href} key={item.label}>
              {item.label}
            </Link>
          ))}
        </div> */}
      </div>
    </footer>
  );
};

export default DashboardFooter;
